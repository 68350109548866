import React from "react"
import Layout from "components/Layout/Layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { FaAngleRight } from "react-icons/fa"
import { Container, Row, Col } from "reactstrap"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Wrapper = styled(Row)`
  max-width: 750px;
  padding: 30px 0 0 0;
`
const BlogCol = styled(Col)`
  padding: 0 0 40px 0;
  display: flex;
  flex-direction: column;
`
export const HeroImage = styled(Img)`
  margin: 30px 0;

  @media only screen and (max-width: 520px) {
    height: 300px;
  }
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  color: #000000;
  font-family: "Libre Franklin";
  font-weight: bold;
  padding-top: 20px;
  text-decoration: none;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`
export const ProfileWrapper = styled.div`
  width: 100%;
  height: 52px;
`
export const ProfileImageWrapper = styled.div`
  width: 52px;
  height: 52px;
  float: left;
  margin-right: 10px;
`
export const ProfileImage = styled(Img)`
  border-radius: 100px;
`
export const ProfileTextWrapper = styled.div`
  max-width: 650px;
  height: 52px;
  float: left;
`
const HeadingThree = styled.h4`
  ${props => props.theme.main.fonts.headingThree};

  font-size: 16px;
  font-weight: 600;
  margin: 7px 0 0 0;
  @media only screen and (max-width: 824px) {
    font-size: 14px;
  }
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  color: #7f7a78;
  font-size: 13px;
  line-height: 1.8;
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
  @media only screen and (max-width: 824px) {
    font-size: 16px;
  }
`
const BlogLinkWrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  text-align: right;
`
export const BlogImage = styled.img`
  max-width: 100%;
  height: auto;
  align-self: ${props => (props.align === 'left' ? 'flex-start' : (props.align === 'right' ? 'flex-end' : props.align))};
`

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <ParagraphTwo>{children}</ParagraphTwo>
    ),
    [INLINES.EMBEDDED_ENTRY]: node => {
      return (
        <BlogImage
          src={node.data.target.fields.image["en-US"].fields.file["en-US"].url}
          align={node.data.target.fields.alignment["en-US"]}
        />
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return (
        <BlogImage
          src={node.data.target.fields.image["en-US"].fields.file["en-US"].url}
          align={node.data.target.fields.alignment["en-US"]}
        />
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {      
      let { description, title, file } = node.data.target.fields
      return <img src={file["en-US"].url} />
    },
  },
}

export default ({ data }) => {
  const post = data.contentfulBlogPost
  return (
    <Layout>
      <Container fluid>
        <Row>
          <Col>
            <Wrapper>
              <BlogCol>
                <HeadingTwo>{post.title}</HeadingTwo>
                <ProfileWrapper>
                  <ProfileImageWrapper>
                    <ProfileImage fixed={post.author.photo.fixed} />{" "}
                  </ProfileImageWrapper>
                  <ProfileTextWrapper>
                    <HeadingThree>{post.author.name}</HeadingThree>
                    <HeadingFour>{post.publishDate}</HeadingFour>
                  </ProfileTextWrapper>
                </ProfileWrapper>
                <HeroImage fluid={post.heroImage.fluid} />
                {documentToReactComponents(post.body.json, options)}
                <BlogLinkWrapper>
                  <Link className="blogLink" to="/blog/">
                    View More Posts <FaAngleRight />
                  </Link>
                </BlogLinkWrapper>
              </BlogCol>
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY")
      author {
        name
        photo {
          fixed(width: 52, height: 52) {
            ...GatsbyContentfulFixed
          }
        }
      }
      heroImage {
        fluid(quality: 90, maxWidth: 4160, maxHeight: 2300) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      body {
        json
      }
    }
  }
`
